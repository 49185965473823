.custom-navbar {
    position: relative;
}

.custom-navbar .navbar-collapse {
    transition: transform 0.3s ease-in-out;
}

@media (max-width: 992px) {
    a.navbar-brand {
        z-index: 99;
    }

    .navbar-toggler-icon {
        background-image: none !important;
        width: 30px;
        height: 3px;
        background-color: #ffffff;
        position: relative;
    }

    .navbar-toggler-icon::before,
    .navbar-toggler-icon::after {
        content: '';
        position: absolute;
        width: 45px;
        height: 4px;
        border-radius: 3px;
        background-color: #ffffff;
        left: 0;
        transition: transform 0.3s ease-in-out;
    }

    .navbar-toggler-icon::before {
        top: -14px;
    }

    .navbar-toggler-icon::after {
        top: 14px;
    }

    .navbar-toggler.is-open .navbar-toggler-icon {
        background-color: transparent;
    }

    .navbar-toggler.is-open .navbar-toggler-icon::before {
        transform: translateY(14px) rotate(45deg);
    }

    .navbar-toggler.is-open .navbar-toggler-icon::after {
        transform: translateY(-14px) rotate(-45deg);
    }

    .ms-auto.navbar-nav a {
        border-radius: 5px;
        padding: 20px 20px;
        margin: 5px 0;
        box-shadow: 5px 5px 40px #2818e9;
        background-color: #2616e8;
        -webkit-transition: all 0.5s ease-in;
        -moz-transition: all 0.5s ease-in;
        -o-transition: all 0.5s ease-in;
        transition: all 0.5s ease-in;
    }

    .ms-auto.navbar-nav a:hover {
        background-color: #ff01a7;
        color: #ffffff;
    }

    .custom-navbar .navbar-collapse {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #2e1dfb;
        transform: translateX(-100%);
        z-index: 1050;
        padding: 20% 5%;
    }

    .custom-navbar .navbar-collapse.show {
        padding: 20% 5%;
        transform: translateX(0);
    }

    .custom-navbar .navbar-toggler {
        position: relative;
        z-index: 1100;
        border: none;
    }

    .ms-auto.navbar-nav a:first-child {
        margin-top: 60px;
    }
    a.navbar-brand{
        z-index: 99;
    }
    body .navbar a {
        z-index: 9999;
    }
    .navbar-toggler-icon {
        width: 30px;
        height: 4px!important;
        border-radius: 3px;
    }
    button:focus:not(:focus-visible) {
        box-shadow: none;
    }
}
