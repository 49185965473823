body .navbar{
  background-color: #2e1dfb;
  padding: 10px 20px;
  position: fixed;
  width: 100%;
  z-index: 99;
}

/* CustomCursor.css */
* {
  cursor: none;
}

.cursor {
  position: fixed;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #ff01a7;
  pointer-events: none;
  z-index: 999999999999999;
  transition: transform 0.2s, opacity 0.2s;
}

.cursor.opacity-low {
  background-color: #ffffff7a;
}




.fechaprograma{
  display: inline-flex;
}
.fechaprograma h3{
  margin-right: 10px;
}

#root{
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

body .navbar .nav-link.active{
  color: #ff01a7;
}

body .navbar .nav-link{
  color: #ffffff;
}

body .navbar a{
  text-decoration: none;
  margin-right: 20px;
  color: #ffffff;
  font-family: 'SnowflakeSans'!important;
  font-weight: 400;
  font-style: normal;
  -webkit-transition: all 0.5s ease-in;
  -moz-transition: all 0.5s ease-in;
  -o-transition: all 0.5s ease-in;
  transition: all 0.5s ease-in;
}

body .navbar a:hover{
  color: #ff01a7;
}



a.btn.btn-link{
  text-decoration: none;
  margin-right: 20px;
  color: #ff01a7;
  font-family: 'SnowflakeSans'!important;
  font-weight: 400;
  font-style: normal;
}

.persona{
  display: flex;
}

.persona a{
  color:#f60ea7;
  text-decoration: none;
}

.persona img{
  border-radius: 50px;
  width: 50px;
  border: 2px solid #f60ea7;
  margin: 0 10px 0 0;
}

.persona p{
  margin-right: 30px;
}

.banner{
  height: 90vh;
  background-color: #2e1dfb;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

img.vml-logo-img{
  max-width: 700px;
  margin: 0 auto;
}

.banner .vml-logo {
  max-width: 700px;
  margin: 0 auto;
}

body .vml-logo button{
  background-color: #ff01a7;
  border: 2px solid #ff01a7;
  color: #ffffff;
  margin-top: 30px;
}

body .vml-logo button:hover{
  background-color: #ff00a600!important;
  border: 2px solid #ffffff!important;
  color: #ffffff!important;
  box-shadow: 0px 5px 10px #0000002e;
}

.banner .fondo-banner {
  max-height: 90vh;
  float: right;
}

.headermodule{
 margin-bottom: 0px;
}

.menuprogramas.nav{
  margin-bottom: 50px;
  width: 100%;
  text-align: right;
  padding: 10px;
  background-color: #2e1cfb;
  display: flex;
  justify-content: flex-end;
}

.menuprogramas.nav a{
  color:#ffffff;
  font-size: 10px;
}
 

.headermodule img{
  width: 100vw;
  height: 300px;
  object-fit: cover;
}

body h1{
  color: #ff01a7;
  font-family: 'SnowflakeInline';
  font-style: normal;
  font-size: 6em;
  line-height: 1em;
  margin-bottom: 25px;
}

body h2{
  color: #ff01a7;
  font-family: 'SnowflakeSans';
  font-weight: 900;
  font-style: normal;
  font-size: 3em;
}

body h3{
  color: #ff01a7;
  font-family: 'SnowflakeSans';
  font-weight: 900;
  font-style: normal;
  font-size: 1.3em;
}

body h4{
  color: #ff01a7;
  font-family: 'SnowflakeSans';
  font-weight: 900;
  font-style: normal;
  font-size: 1.3em;
}

body .card-title h5{
  color: #ff01a7;
  font-family: 'SnowflakeSans';
  font-weight: 900;
  font-style: normal;
  font-size: 1.1em;
}

.list-group-item{
  color: #000000!important;
  font-family: 'SnowflakeSans';
  font-weight: 200;
  font-style: normal;
  font-size: 1em;
  border: 1px solid #f60ea7!important;
  border-radius: 0px!important;
  margin-bottom: 20px;
  -webkit-transition: all 0.5s ease-in;
  -moz-transition: all 0.5s ease-in;
  -o-transition: all 0.5s ease-in;
  transition: all 0.5s ease-in;
}

.list-group-item:hover{
  background-color: #f60ea7; 
  border: 1px solid #f60ea7!important;
  color: #ffffff!important;
}

.list-group-item:hover strong{
  color: #ffffff!important;
}

.list-group-item strong{
  color: #ff01a7!important;
  font-family: 'SnowflakeSans';
  font-weight: 500;
  font-size: 1.5em;
}

body .btn-primary,body a{
  font-family: 'SnowflakeSans'!important;
  font-weight: 500;
  font-style: normal;
}

body p{
  font-family: 'SnowflakeSans';
  font-weight: normal;
  color: #000000;
}





/*--- Formulario ---*/
body label{
  font-family: 'SnowflakeSans';
  font-weight: 500;
  color: #ff01a7;
}

.form-row{
  display: flex;
}

.form-group{
  margin: 0 0 40px 0;
}

body .form-control{
  border: 0px;
  border-bottom: 2px solid #2e1dfbca!important;
  border-radius: 0px;
  color: #2e1dfbca;
  font-family: 'SnowflakeSans';
  font-weight: normal;
}

#registrationForm .form-group.col-md-6:first-child {
  width: 50%;
  margin-right: 2%;
}

body .form-control:focus {
    border: 0px;
    border-bottom: 2px solid #2e1dfbca!important;
    background-color: none;
    border-color: #ffffff00;
    box-shadow:none;
    color: #2e1dfbca;
    border-radius: 0px;
}

.dropzonestyle div{
  border-color: #2e1dfbca !important;
}

.dropzonestyle div{
  margin-top: 10px;
  padding: 50px;
}

.dropzonestyle p{
  color: #2e1dfbca!important;
  text-align: center;
  margin-bottom: 0px;
  font-weight: 400;
}

.dropzonestyle .file-name {
  font-weight: bold;
  padding: 0;
  text-align: left;
  width: 100%;
  display: flex;
}

#aplicarbutton{
  margin: 50px 0 50px 0;
}





/*--- Formulario Checkbox ---*/
.custom-checkbox {
  display: none;
}

.custom-checkbox + label {
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  display: inline-block;
}

.custom-checkbox + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 19px;
  height: 19px;
  border: 2px solid #2e1cfb;
  border-radius: 3px;
  background-color: #fff;
}

body .custom-checkbox:checked + label:before {
  background-color: #2e1cfb;
  content: '✔';
  color: #fff;
  text-align: center;
  line-height: 20px;
  font-family: 'SnowflakeSans'!important;
}




/*--- Card ---*/
.card{
  margin: 5% 1%;
  -webkit-transition: all 0.5s ease-in;
  -moz-transition: all 0.5s ease-in;
  -o-transition: all 0.5s ease-in;
  transition: all 0.5s ease-in;
}

.card:hover{
  box-shadow: 0px 10px 40px #81818182;
}



body .btn-primary {
  height: 45px;
  color: #fff;
  background-color: #2e1dfb;
  border-color: #2e1dfb;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0px;
}

body .btn-primary:hover {
  color: #fff!important;
  background-color: #ff01a7!important;
  border-color: #ff01a7!important;
}

body .card .btn-primary {
  color: #fff;
  background-color: #2e1dfb;
  border-color: #2e1dfb;
  text-transform: uppercase;
  width: 250px;
  margin: 30px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0px;
}

.boton{
  width: 200px;
  border-radius: 0px;
}

.fechalugar{
  display: flex;
  padding: 0px!important;
}

.fechalugarleft{
  border: 1px solid #ff01a7;
  padding: 20px;
  margin: 20px 20px 50px 0px;
  border-radius: 0px;
}

.fechalugarright{
  border: 1px solid #ff01a7;
  padding: 20px;
  margin: 20px 0px 50px 20px;
  border-radius: 0px;
}

.fechalugarleft:hover{
  background-color: #ff01a7;
  color: #ffffff;
}

.fechalugarright:hover{
  background-color: #ff01a7;
  color: #ffffff;
}

.fechalugarright:hover p, .fechalugarleft:hover p{
  color: #ffffff;
}

.fechalugarleft:hover h3, .fechalugarright:hover h3{
  color: #ffffff;
}


.active.nav-link{
  color: #ff01a7!important;
}

#map {
  height: 50vh;
  width: 100vw;
}


/*--- Typing text---*/
.typing-text {
  height: 0;
  overflow: hidden;
  display: inline-block;
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.opacitycontainer.fade-in .typing-text {
  height: auto;
  opacity: 1;
}





#programas, #aplicar, #acercade{
  padding: 7% 0 0 0;
}  

footer{
  background-color: #2e1dfb;
  color: #ffffff;
  position: relative;
  width: 100vw;
  bottom: 0px;
}

footer .contactofooter{
  margin: 20px 0;
}

footer .contactofooter a{
  color: #ffffff;
  text-decoration: none;
}

footer p{
  margin-bottom: 0px;
  color: #ffffff;
}

.redesfooter{
  margin: 10px 0 20px 0;
}

.redesfooter a{
  color: #ffffff;
}



/*--- Padding del container programas---*/
.paddcontainer{
  padding:3% 0;
}


/*--- Opacity ---*/
.opacitycontainer{
  opacity: 0;
}

.fade-in {
  animation: fadeInAnimation ease-in 1s;
  animation-fill-mode: forwards;
}

.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.962); 
  z-index: 1000; 
}

.loader-container .spinner {
    width: 350px;
    background-size: cover;
    height: 100px;
    background-image: url(../public/images/vml-academy.png);    
    background-repeat: no-repeat;
    animation: spin 2s linear infinite;
}

.vml-logo {
  animation: fadeInUp 1s ease forwards;
}

.fondo-banner{
  animation: fadeInRight 1s ease forwards;
}





.listcrono{
  color: #f60ea7!important;
}





.mobile-menu-navigation{
  display: flex;
  justify-content: flex-end;
}
.mobile-menu-navigation button p{ 
  margin-bottom: 0;
  color: #ffffff;
}




/*--- Lista Users ---*/
.registrados{
  margin: 10%;
}

.registrados li{ 
  margin: 30px 0;
  padding: 2%;
  border:1px solid #000000;
  list-style: none;
}












/*--- key Frames ---*/
@keyframes fadeInUp {
  from {
      opacity: 0;
      transform: translateY(100px);
  }
  to {
      opacity: 1;
      transform: translateY(0);
  }
}

@keyframes fadeInRight {
  from {
      opacity: 0;
      transform: translateX(100px);
  }
  to {
      opacity: 1;
      transform: translateY(0);
  }
}

@keyframes spin {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes fadeInAnimation {
  0% { 
    opacity: 0;
    transform: translateY(100px);
  }
  100% { 
    opacity: 1;
    transform: translateY(0px);
  }
}


/*--- Web Fonts ---*/
@font-face {
  font-family: 'SnowflakeInline';
  src: url('fonts/SnowflakeInline-Inlinex1.woff') format('woff'),
       url('fonts/SnowflakeInline-Inlinex2.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SnowflakeSans';
  src: url('fonts/SnowflakeSans-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'SnowflakeSans';
  src: url('fonts/SnowflakeSans-Book.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SnowflakeSans';
  src: url('fonts/SnowflakeSans-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'SnowflakeSans';
  src: url('fonts/SnowflakeSans-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'SnowflakeSans';
  src: url('fonts/SnowflakeSans-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'SnowflakeSans';
  src: url('fonts/SnowflakeSans-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'SnowflakeSans';
  src: url('fonts/SnowflakeSans-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'SnowflakeSans';
  src: url('fonts/SnowflakeSans-BlackItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'SnowflakeSans';
  src: url('fonts/SnowflakeSans-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'SnowflakeSans';
  src: url('fonts/SnowflakeSans-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
}




/*--- Mobile ---*/
@media only screen and (max-width: 768px) {
  .cursor {
    display: none;
  }  
  .persona {
    display: flex;
    flex-direction: column;
  }
  #acercade, #aplicar, #programas {
    padding: 5% 20px;
  }
  .navbar.navbar-expand-lg.navbar-light img{
    height: 100px;
  }
  .banner .vml-logo {
    margin: 0 auto;
    max-width: 100px;
  }
  #bannerhome{
    overflow: hidden;
  }
  .banner .fondo-banner {
    max-height: 100vh;
  }
  .banner {
    display: flex;
    height: 90vh;
    justify-content: space-between;
    margin-bottom: 50px;
    flex-direction: column;
  }
  .fechalugarleft, .fechalugarright {
    margin: 5%;
    width: 90% !important;
  }
  .fechalugar {
    display: flex;
    justify-content: space-between;
    padding: 0 !important;
    flex-direction: column;
    align-items: center;
  }
  body .card .btn-primary {
    width: 200px;
  }
  .fechalugar {
    display: flex;
    padding: 0px !important;
    justify-content: space-between;
  }  
  body h1 {
    font-size: 3em;
  }  
  .card{
    margin-bottom: 10%;
  }  
}    


@media only screen and (max-width: 480px) {
  .persona a, .persona p {
    color: #f60ea7;
    text-decoration: none;
    font-size: 12px;
  }
  .mobile-menu-navigation{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    margin-top: 0px;
  }
  body h1 {
    margin-top: 50px;
    font-size: 2.7em;
  }
  .listcrono{
    display: flex;
    flex-direction: column;
  }
  .headermodule img {
    width: 100vw;
    height: auto;
    margin-top: 70px;
    object-fit: contain;
  }
  .banner {
    height: 100vh;
  }
  .banner .fondo-banner {
    opacity: 0.3 !important;
  }
  .banner .vml-logo{
    max-width: 480px;
    position: absolute;
    display: flex;
    z-index: 9;
    justify-content: center;
    flex-direction: column;
  }   
  .banner .vml-logo img{
    max-width: 300px;
  }  
  #bannerhome {
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}




